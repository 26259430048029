
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import { urlParams } from "@/utils";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { DeceasedDonorOrgan, DeceasedDonorOrganDonations, ListDonor, StateDonorList } from '@/store/deceasedDonors/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import {AllocationOfferResponseCodeValues, AllocationOfferTypeValues, AllocationOfferConcludedReason} from "@/store/allocations/types";

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    TextInput,
    CheckboxInput,
    SelectInput,
    SubSection
  }
})
export default class WidgetExdDonors extends mixins(DateUtilsMixin) {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashExdDonors) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getExdDonors', { namespace: 'dashboard' }) getExdDonors!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDefaultPaginationOptions', { namespace: 'utilities' }) getDefaultPaginationOptions!: any;
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string|undefined;
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;

  public currentPage = 1;
  public perPage = 5;
  public errorMessage = null;

  private isWidgetLoaded = false;

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    setTimeout(() => {
      _vm.loadIfStillViewingDashboard();
    }, 5000);
  }

  /**
   * NOTE: we are relying on checking route 'name' to handle a special case for widget loading,
   * so we need to ensure this route name check matches dashboard route name in router.ts
   */
  loadIfStillViewingDashboard(): void {
    const viewingDashboard = this.$route.name === 'index';
    if (!viewingDashboard) return;

    Promise.all([
      this.loadData()
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  public loadData(search='', sort='') {
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });

    this.$store.dispatch(
      'dashboard/loadExdDonors', {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
    });
  }

  public updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData(search_params, sort_params);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashExdDonors',
      value: this.buildExdDonorsEditState(preferences),
    });
    this.isWidgetLoaded = true;
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idExdDonors").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildExdDonorsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.exdDonors);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ exdDonors: {} }, currentSettings);
    newSettings.exdDonors = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  get tableData() {
    const exdDonors: any = this.getExdDonors.entries || [];
    let results: any[] = [];
    exdDonors.map((record: any) => {
      record.organ_consents.forEach((organ: any) => {
        const organName = this.organName(organ.organ_code);
        const organ_offers = (organ.organ_offers || []);
        let recipient_names: string[] = [];
        let recipient_ids: string[] = [];
        let program_identifiers: string[] = [];
        organ_offers.forEach((organ_offer: any) => {
          if(![AllocationOfferResponseCodeValues.Accept,
            AllocationOfferResponseCodeValues.AcceptWithCondition].includes(organ_offer.offer_response_code)) {
            return;
          }
          // TPGLI-7081
          // when offer is not primary offer don't show in dashboard
          // when offer does not accept exceptional distribution don't show in dashboard
          // when offer is not yet concluded or no transplant date added to recipient journey don't show in dashboard
          if(organ_offer.offer_type_code !== AllocationOfferTypeValues.Primary) return;
          if(!organ_offer.accepted_exceptional_distribution) return;
          if(!organ_offer.concluded && organ_offer.concluded_reason_code !== AllocationOfferConcludedReason.Transplanted) return;
          if (organ_offer.recipient_id) {
            recipient_names.push(`${organ_offer.first_name} ${organ_offer.last_name}`);
            recipient_ids.push(organ_offer.client_id);
            program_identifiers.push(organ_offer.program_identifier);
          }
        });
        if(recipient_names.length > 0) {
          results.push({
            client_id: record.client_id || null, // record id
            deceased_donor_id: record.deceased_donor_id || null, // tgln id
            location: record.hospital?.hospital_name_info?.short_name || null,
            new_exd: record.exceptional_distribution ? this.$t('yes').toString() : this.$t('no').toString(),
            change_date_time: this.parseDisplayDateTimeUiFromDateTime(record.updated_at),
            post_release: record.post_release ? this.$t('yes').toString() : this.$t('no').toString(),
            organsConsented: organName,
            recipient_id: recipient_ids.join(', '),
            program_identifier: program_identifiers.join(', '),
            recipient_name: recipient_names.join(', '),
            link_for_exd_consent: recipient_names.length > 0 ? record.client_id : null
          });
        }
      });
    });
    return results;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.tableData,
      columns: [
        { label: this.$t('donor_id').toString(), field: 'deceased_donor_id', sortable: true },
        { label: this.$t('donor_location').toString(), field: 'location', sortable: true },
        { label: this.$t('change_date_time').toString(), field: 'change_date_time', sortable: false },
        { label: this.$t('new_exd').toString(), field: 'new_exd', sortable: false },
        { label: this.$t('post_release_indicator').toString(), field: 'post_release', sortable: false },
        { label: this.$t('organ').toString(), field: 'organsConsented', sortable: true, },
        { label: this.$t('recipient_id').toString(), field: 'recipient_id', sortable: false },
        { label: this.$t('recipient_name').toString(), field: 'recipient_name', sortable: false },
        { label: this.$t('transplant_program').toString(), field: 'program_identifier', sortable: false },
        { label: this.$t('link_for_exd_consent').toString(), field: 'link_for_exd_consent', sortable: false },
      ],
      empty: this.$t('no_exd_donors').toString(),
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'deceased_donor_id', type: 'asc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        setCurrentPage: this.currentPage,
        mode: 'pages',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
        position: 'bottom'
      }
    };
  }
}
